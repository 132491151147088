export const environment = {
    env: 'prod',
    production: true,
    firebase: {
        apiKey: 'AIzaSyDfiSPUlcjf-NEqqL7MECt_q05Eq9EB-HE',
        authDomain: 'listique-site-prod.firebaseapp.com',
        databaseURL: 'https://listique-site-prod.firebaseio.com',
        projectId: 'listique-site-prod',
        storageBucket: 'listique-site-prod.appspot.com',
        messagingSenderId: '23885857293',
        appId: '1:23885857293:web:666e9c4fb10ba6be'
    },
    hostUrl: 'https://listique.com',
    membersUrl: 'https://members.listique.com',
    api: {
        host: 'https://api.listique.com',
        // host: 'http://localhost:5000',
        version: 'v1'
    },
    socials: {
        facebook: 'https://www.facebook.com/listiqueuk',
        twitter: 'https://twitter.com/listique_uk',
        linkedin: 'https://www.linkedin.com/company/cater-to-london/',
        instagram: 'https://www.instagram.com/listique'
    },
    googleApiKeys: {
        googleMapsEmded: 'AIzaSyD1_FqZsc0EnqH5nev7FG6roihpYZcDMkA',
        reCaptcha: '6LeFAcUUAAAAAJMNCIBoFYORQZUEzQvsGumdX9FZ'
    },
    algolia: {
        appId: '654CIJ4XLE',
        searchApiKey: 'f5320eeee6c0535799146d9023da39ef',
        indexName: 'listique'
    }
};
