import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        // loadChildren: () => import(`./site-container/site-container.module`).then(m => m.SiteContainerModule)
        loadChildren:
            () => import('./site-container/site-container.module').then(m => m.SiteContainerModule),
    },
    { path: '**', redirectTo: '/404' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabled',
        // preloadingStrategy: PreloadAllModules
    })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
