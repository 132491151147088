export const seoData = {
    pages: {
        '/': {
            title: 'Listique | The Luxury Directory',
            metaDescription:
                'Listique is the only ‘curated supplier directory for private households of High and Ultra-High-Net-Worth Individuals in London’',
            slug: '/',
        },
        '/categories': {
            title: 'Browse our Directory',
            metaDescription: 'Listique’s vetted and approved partners - the best of the best',
            slug: '/categories',
        },
        '/events': {
            title: 'Events',
            metaDescription: 'All of the must have dates for your diary',
            slug: '/events',
        },
        '/magazine': {
            title: 'Magazine',
            metaDescription: 'An intriguing, informative luxury publication, The Listique Magazine. Read, learn and be inspired.',
            slug: '/magazine',
        },
        '/how-it-works': {
            title: 'How it Works',
            metaDescription: 'Learn how it works – Listique connects luxury businesses (partners) with our clients who can use their services (members). It’s a win/win all round.',
            slug: '/how-it-works',
        },
        '/apply': {
            title: 'Apply',
            metaDescription: 'Apply for a Listique Membership, or a Listique Partnership.',
            slug: '/apply',
        },
        '/what-is-listique': {
            title: 'What is Listique',
            metaDescription: 'A directory where staff of H/UHNWI in London find the very best, newest and most luxurious products and services for their employer, and fast.',
            slug: '/what-is-listique',
        },
        '/contact': {
            title: 'Contact',
            metaDescription: 'Contact Listique staff members here for more information.',
            slug: '/contact',
        },
        '/legals/privacy-policy': {
            title: 'Privacy Policy',
            metaDescription: null,
            slug: '/legals/privacy-policy',
        },
        '/legals/terms-of-use': {
            title: 'Terms of Use',
            metaDescription: null,
            slug: '/legals/terms-of-use',
        },
        '/legals/gdpr': {
            title: 'GDPR',
            metaDescription: null,
            slug: '/legals/gdpr',
        },
        '/404': {
            title: 'Page not found',
            metaDescription: 'Oops! Looks like something went wrong.',
            slug: '/404',
        },
    },
    defaults: {
        socialImage: 'https://staging.listique.com/assets/social.jpg',
        twitterHandle: '@listique_uk',
        facebookHandle: 'listiqueuk',
        title: 'Listique',
        metaDescription:
            'Listique is the only ‘curated supplier directory for private households of High and Ultra-High-Net-Worth Individuals in London’',
        metaKeywords: [''],
        ogPageType: 'website',
        slug: null,
    },
};
