import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MarkdownModule } from 'ngx-markdown';
import { HttpInterceptorService } from './shared/services/http-interceptor.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { environment } from '../environments/environment';
import { SeoService } from './shared/services/seo.service';
import { ListiqueApiModule } from '@listique/api';
import { GenericEntityModule } from 'generic-entity';
import { RecaptchaModule, RecaptchaFormsModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        BrowserAnimationsModule,
        MatIconModule,
        HttpClientModule,
        MarkdownModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirePerformanceModule,
        ListiqueApiModule.forRoot(environment.api),
        GenericEntityModule,
        RecaptchaModule,
        RecaptchaFormsModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.googleApiKeys.reCaptcha,
            } as RecaptchaSettings,
        },
        SeoService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
