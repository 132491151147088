import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { seoData } from '../data/seo';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';

export interface MetaConfig {
    socialImage?: string;
    twitterHandle?: string;
    facebookHandle?: string;
    title?: string;
    metaDescription?: string;
    metaKeywords?: string[];
    ogPageType?: string;
    slug: string;
}

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    constructor(private meta: Meta, private title: Title, @Inject(DOCUMENT) private doc: Document) { }

    generateTags(url: string, config?: MetaConfig) {
        config = {
            ...seoData.defaults,
            ...seoData.pages[url],
            ...config,
        };
        this.title.setTitle(
            config.title === null ? seoData.defaults.title : config.title,
        );
        this.meta.updateTag({
            name: 'description',
            content:
                config.metaDescription === null
                    ? seoData.defaults.metaDescription
                    : config.metaDescription,
        });
        this.meta.updateTag({
            name: 'keywords',
            content:
                config.metaKeywords === null
                    ? seoData.defaults.metaKeywords.join(',')
                    : config.metaKeywords.join(','),
        });
        this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
        this.meta.updateTag({
            name: 'twitter:site',
            content:
                config.twitterHandle === null
                    ? seoData.defaults.twitterHandle
                    : config.twitterHandle,
        });
        this.meta.updateTag({
            name: 'twitter:title',
            content:
                config.title === null ? seoData.defaults.title : config.title,
        });
        this.meta.updateTag({
            name: 'twitter:description',
            content:
                config.metaDescription === null
                    ? seoData.defaults.metaDescription
                    : config.metaDescription,
        });
        this.meta.updateTag({
            name: 'twitter:image',
            content:
                config.socialImage === null
                    ? seoData.defaults.socialImage
                    : config.socialImage,
        });
        this.meta.updateTag({
            property: 'og:type',
            content:
                config.ogPageType === null
                    ? seoData.defaults.ogPageType
                    : config.ogPageType,
        });
        this.meta.updateTag({
            property: 'og:site_name',
            content:
                config.facebookHandle === null
                    ? seoData.defaults.facebookHandle
                    : config.facebookHandle,
        });
        this.meta.updateTag({
            property: 'og:title',
            content:
                config.title === null ? seoData.defaults.title : config.title,
        });
        this.meta.updateTag({
            property: 'og:description',
            content:
                config.metaDescription === null
                    ? seoData.defaults.metaDescription
                    : config.metaDescription,
        });
        this.meta.updateTag({
            property: 'og:image',
            content:
                config.socialImage === null
                    ? seoData.defaults.socialImage
                    : config.socialImage,
        });
        this.meta.updateTag({
            property: 'og:url',
            content: `${environment.hostUrl}${
                config.slug === null ? seoData.defaults.slug : config.slug
                }`,
        });

        this.createLinkForCanonicalURL(config);
    }

    createLinkForCanonicalURL(config: MetaConfig) {
        let link: HTMLLinkElement = this.doc.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.doc.head.appendChild(link);
        link.setAttribute('href', `${environment.hostUrl}${
            config.slug === null ? seoData.defaults.slug : config.slug
            }`);
    }
}
