import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';

import { isPlatformServer } from '@angular/common';

import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
    constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        if (isPlatformServer(this.platformId) && req.url.includes('/assets')) {
            return next.handle(
                req.clone({
                    url: `${environment.hostUrl}${req.url}`,
                }),
            );
        }

        return next.handle(req);
    }
}
