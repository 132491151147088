import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>
    `,
})
export class AppComponent {
    constructor(
        private matIconRegistry: MatIconRegistry,
        sanitizer: DomSanitizer,
    ) {
        matIconRegistry.addSvgIcon(
            'facebook',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/facebook.svg',
            ),
        );
        matIconRegistry.addSvgIcon(
            'twitter',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/twitter.svg',
            ),
        );
        matIconRegistry.addSvgIcon(
            'youtube',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/youtube.svg',
            ),
        );
        matIconRegistry.addSvgIcon(
            'linkedin',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/linkedin.svg',
            ),
        );
        matIconRegistry.addSvgIcon(
            'pinterest',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/pinterest.svg',
            ),
        );
        matIconRegistry.addSvgIcon(
            'instagram',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/instagram.svg',
            ),
        );
        matIconRegistry.addSvgIcon(
            'search',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/search.svg',
            ),
        );
        matIconRegistry.addSvgIcon(
            'chevron-up',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/pinterest.svg',
            ),
        );
        matIconRegistry.addSvgIcon(
            'chevron-down',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/chevron-down.svg',
            ),
        );
        matIconRegistry.addSvgIcon(
            'chevron-right',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/chevron-right.svg',
            ),
        );
        matIconRegistry.addSvgIcon(
            'close',
            sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/close.svg'),
        );
        matIconRegistry.addSvgIcon(
            'menu',
            sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/menu.svg'),
        );
        matIconRegistry.addSvgIcon(
            'recommendation',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/recommendation.svg',
            ),
        );
        matIconRegistry.addSvgIcon(
            'add',
            sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/add.svg'),
        );
        matIconRegistry.addSvgIcon(
            'link',
            sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/link.svg'),
        );
        matIconRegistry.addSvgIcon(
            'contract-search',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/contract-search.svg',
            ),
        );
        matIconRegistry.addSvgIcon(
            'partner',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/partner.svg',
            ),
        );
        matIconRegistry.addSvgIcon(
            'briefcase',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/briefcase.svg',
            ),
        );
        matIconRegistry.addSvgIcon(
            'check',
            sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/check.svg'),
        );
        matIconRegistry.addSvgIcon(
            'partner-scales',
            sanitizer.bypassSecurityTrustResourceUrl(
                '/assets/icons/partner-scales.svg',
            ),
        );
        matIconRegistry.addSvgIcon(
            'tie',
            sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/tie.svg'),
        );
        matIconRegistry.addSvgIcon(
            'search-results',
            sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/search-results.svg'),
        );
    }
}
